.transaction{
    padding: 2rem 2.0625rem;
}
.transaction-top{
    /* padding: 1.125rem 2.5rem; */
    /* background: #FFFFFF; */
    
    border-radius: 7.80686px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.transaction-top-left, .transaction-top-center, .transaction-top-right{
    padding: 1.125rem 2rem;
    width: 33.3%;
    height: 175px;
}
.transaction-top-left,.transaction-top-right, .transaction-top-center{
    background-color: white;
    box-shadow: 4.87929px 4.87929px 19.5172px rgba(0, 0, 0, 0.07);
}
.selected-box{
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.07);
    background: rgba(115, 115, 115, 0.03);
}
.transaction-top-left{
    display: flex;
    flex-direction: column;
}
.transaction-top-left h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.9);
}
.current{
    margin-top: 10px;
    width: 40%;
}
.current, .main, .sub{
    padding: 0.3625rem 0.8rem;
    background: rgba(179, 28, 47, 0.15);
    border-radius: 5.55208px;
}
.current p, .main p, .sub p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    color: rgba(43, 43, 43, 0.9);
}
.main{
    background: rgba(5, 139, 66, 0.44);
}

.trans-phone{
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.4);
}
.trans-phone span svg{
    margin-right: 8px;
    font-size: 1.2rem;
    color: #555555;
}

.trans-center-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.available{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: rgba(43, 43, 43, 0.4);
}
.main-balance{
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.9);
}

.transaction-head{
    margin-top: 33px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    color: #2B2B2B;
}
.transaction-body{
    margin-top: 21px;
}
.dashboardCategory{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.categoryLeft{
    display: flex;
    align-items: center;
    gap: 5px;
}
.status{
    padding: 0.5rem 0.8rem;
    border: 0.5px solid rgba(43, 43, 43, 0.4);
    border-radius: 8px;
}
.status p, .active p{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2B2B2B;
}
.active{
    padding: 0.5rem 0.8rem;
    background-color:  #B11226;
    border-radius: 8px;
    border: none;
}
.transaction-table{
    margin-top: 21px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.empty-animate{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.categoryRight{
    width: 40%;
    display: flex;
    align-items: center;
    gap: 3px;
}
.categoryRight select, .categoryRight input{
    width: 100%;
    padding: 0.5rem 0.5rem;
    background: #FFFFFF;
    border: none;
    border-radius: 8px;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2b2b2baf;
}
.categoryRight select optgroup{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2B2B2B;
    opacity: 0.3;
}
.categorySearch{
    display: flex;
    align-items: center;
    padding: 0.6rem;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 8px;
}
.categorySearch input{
    width: 90%;
    padding-left: 10px;
    background-color: transparent;
    border: none;
}
.categorySearch input::placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 20px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
}
.categoryLeftMobile{
    display: none;
}
.categoryLeftDesktop{
    display: block;
}
.categoryLeft{
    width: 65%;
    display: flex;
    align-items: center;
    gap: 12px;
}
@media (max-width: 1350px) {
    .current, .main, .sub{
        padding: 0.2625rem 0.6rem;
    }
    .current p, .main p, .sub p{
        font-size: 0.5rem;
    }
}
@media (max-width: 1210px) {
    .main-balance{
        font-size: 2rem;
    }
    .transaction-top-left h1{
        font-size: 1.4rem;
    }
    .trans-phone{
        font-size: 1.2rem;
    }
}
@media (max-width: 1100px) {
    .main-balance{
        font-size: 1.6rem;
    }
    .transaction-top-left h1{
        font-size: 1.4rem;
    }
    .trans-phone{
        font-size: 1.2rem;
    }
}
@media (max-width: 1000px) {
    .transaction{
        position: relative;
        padding: 2rem 1rem;
    }
    .transaction-top{
        margin-top: 0px;
    }
    .transaction-top-left, .transaction-top-center, .transaction-top-right{
        padding: 1.125rem 2rem;
        width: 100%;
        height: 175px;
    }
    .transaction-top-left,.transaction-top-center{
        display: none;
    }
    .dashboardCategory{
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 15px;
        align-items: center;
        /* position: absolute;
        top: 20px; */
    }
    .transaction-head{
        display: none;
    }
    .main-balance{
        margin-top: 10px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 2.5rem;
        color: rgba(0, 0, 0, 0.9);
    }
    .categoryLeftMobile{
        display: block;
        width: 80%;
    }
    .categoryLeftDesktop{
        display: none;
    }
    .categoryLeft{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: left;
        gap: 0px;
    }
    .categorySearch{
        width: 70%;
        display: flex;
        align-items: center;
        padding: 0.6rem;
        border: 1px solid rgba(0, 0, 0, 0.6);
        border-radius: 8px;
    }
    .categorySearch input::placeholder{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.4rem;
        line-height: 0px;
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.6);
    }
    .categoryRight{
        width: 40%;
        display: flex;
        align-items: center;
        gap: 3px;
    }
}
@media (max-width: 600px) {
    .categoryLeft{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 3px;
    }
    .categoryRight{
        width: 68%;
        display: flex;
        align-items: center;
        gap: 3px;
    }
}