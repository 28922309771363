.desc{
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    margin-bottom: -10px;
    font-size: 1.25rem;
    color: #2B2B2B;
    z-index: 0;
}
.desc:hover{
    opacity: 0.7;
}
.desc::after{
    content:'';
    width: 100%;
    height: 4px;
    opacity: 0;
    
    display: inline-flex;
    background: #B11226;
    border-radius: 12px;
}
.desc.currentDesc::after{
    opacity: 1;
}