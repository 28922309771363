.paymentHead{
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    color: #555555;
}
.paymentCircle{
    margin-top: 18px;
    padding: 0.9rem;
    background: #FFFFFF;
    box-shadow: 0px 6.70051px 10.0508px rgba(0, 0, 0, 0.05), 0px 2.6802px 4.0203px rgba(0, 0, 0, 0.05);
    border-radius: 10.7208px;
}
.paymentAmount{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 21px;
    letter-spacing: -0.120609px;
    color: #202020;
}
.paymentLimit{
    margin-top: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size:  0.5rem;
    line-height: 16px;
    letter-spacing: 0.0670051px;
    color: rgba(32, 32, 32, 0.5);
}
.paymentCircleInner{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.circleBackground{
    fill: none;
    stroke: #E5E7EB;
}
.circleProgress{
    fill: none;
    stroke: #FFA4AF;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.circleText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 38px;
    text-align: center;
    color: #202020;
}
.circleLabel{
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-around;
}
.paid, .limit, .amount{
    display: flex;
    align-items: center;
}
.paid div, .limit div, .amount div{
    width: 5.36px;
    height: 5.36px;
    border-radius: 50%;
}
.paid div{
    background-color: #FFA4AF;
}
.limit div{
    background-color: #E5E7EB;
}
.amount div{
    background-color: #202020;
}
.paid p, .limit p, .amount p{  
    margin-left: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.5625rem;
    line-height: 16px;
    letter-spacing: 0.0670051px;
    color: rgba(32, 32, 32, 0.8);
}
.paymentButton{
    margin-top: 36px;
    width: 100%;
    padding: 0.75rem 1.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 30px;
    color: #555555;
    border: 1px solid #737373;
    border-radius: 8px;
    background-color: transparent;
}
@media (max-width: 1210px) {
    .paymentHead{
        font-size: 0.9rem;;
    }
    .paymentCircle{
        margin-top: 10px;
        padding: 0.5rem;
    }
    .paymentAmount{
        font-size: 0.8rem;
    }
    .paymentLimit{
        margin-top: 6px;
        line-height: 13px;
    }
    .paymentCircleInner{
        margin-top: 0px;
    }
    .circleLabel{
        margin-top: 6px;
        justify-content: space-between;
    }
    .paid div, .limit div, .amount div{
        width: 3.36px;
        height: 3.36px;
    }
    .paid p, .limit p, .amount p{  
        margin-left: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 0.3rem;
    }
    .paymentButton{
        margin-top: 26px;
        padding: 0.75rem 1.5rem;
        font-size: 1.1rem;
        line-height: 30px;
    }
}
@media (max-width: 1000px) {
   
    .paid div, .limit div, .amount div{
        width: 5.36px;
        height: 5.36px;
    }
    .paid p, .limit p, .amount p{  
        margin-left: 5px;
        font-size: 0.675rem;
    }
}