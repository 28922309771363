.directors{
    width: 40%;
}
.director-top{
    display: flex;
    align-items: center;
}
.director-top p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    color: #000000;
}
/* .ant-switch-checked{
    background-color: #AC1337;
} */
.director-top button{
    margin-left: 84px;
    padding: 0.5rem 3.25rem;
    background: #AC1337;
    border: none;
    border-radius: 4.6px;
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4375rem;
    line-height: 34px;
    color: #FFFFFF;
}
.director-top button svg{
    margin-right: 10px;
}
.director-body{
    margin-top: 20px;
    height: 230px;
    overflow-y: scroll;
}
.director-body::-webkit-scrollbar {
    width: 6px;
}
.director-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px #D9D9D9;
    border-radius: 10px;
}
.director-body::-webkit-scrollbar-thumb {
    background: #707070b2;
    border-radius: 15px;
}  
.director{
    margin-top: 27px;
    background: #EDEDED;
    border-radius: 4px;
    padding: 1.1875rem 1.5625rem;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}
.director-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.8);
}
.director-role{
    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.8);
}
.set-settings{
    margin-top: 54px;
}
.set-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.8);
}
.set-setting{
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.2px solid #000000;
}
.set-text-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    color: #000000;
}
.set-text-body{
    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.8);
}
@media (max-width: 1210px) {
    .directors{
        width: 60%;
    }
}
@media (max-width: 1000px) {
    .directors{
        width: 100%;
    }
    .director-top{
        justify-content: space-between;
    }
    .director-top p{
        font-size: 1.25rem;
    }
    .director-top button{
        margin-left: 0px;
        padding: 0.5rem 2.375rem;
        background: #AC1337;
        font-size: 1.0625rem;
    }
    .director-name{
        font-size: 0.875rem;
    }
    .set-head{
        font-size: 0.875rem;
    }
    .set-text-head{
        font-size: 0.875rem;
        color: #000000;
    }
    .set-text-body{
        font-size: 0.625rem;

    }
}