table{
    width: 100%;
}
.tablecell-head {
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14.1906px;
    color: #555555;
}
.tablecell-body{
    cursor: pointer;
}
.recipient{
    display: flex;
    align-items: center;
}
.recipient img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 5px;
}
.recipient-name{
    width: max-content;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #2B2B2B;
}
.recipient-title{
    width: max-content;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #555555;
}
.date{
    width: max-content;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #2B2B2B;
}
.time{
    width: max-content;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #555555;
}
.transaction-text{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;             
    color: #2B2B2B;
}
.status-button button{
    padding: 0.25rem 0.5625rem;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8125rem;
    color: #FFFFFF;
    background: #068B6C;
    border: none;
    border-radius: 4px;
}
.status-button-red button{
    padding: 0.25rem 0.5625rem;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8125rem;
    color: #FFFFFF;
    background:#B11226;
    border: none;
    border-radius: 4px;
}
@media (max-width:1200px) {
    .tablecell-head {
        font-size: 12px;
    }
    .recipient-name{
        font-size: 14px;
    }
    .recipient-title{
        font-size: 12px;
    }
    .date{
        font-size: 14px;
    }
    .time{
        font-size: 12px;
    }
    .transaction-text{
        font-size: 14px;             
    }
    .status-button button{
        padding: 0.25rem 0.5625rem;
        font-size: 0.6125rem;
    }
}
@media (max-width: 1000px) {
    .tablecell-head p{
        font-size: 8px;
        width: max-content;
    }
    .recipient-name{
        font-size: 0.5625rem;
    }
    .recipient-title{
        font-size: 0.5rem;
    }
    .date{
        font-size: 0.5625rem;
        color: #2B2B2B;
    }
    .time{
        font-size: 0.5rem;
        color: #555555;
    }
    .transaction-text{
        font-size: 0.5625rem;             
    }
}