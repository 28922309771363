.key-top{
    display: flex;
    justify-content: right;
}
.key-error-notiication{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    background-color: white;
}
.key-success-notiication{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 1.2rem;
    background-color: white;
}
.key-success-notiication p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    color: rgb(4, 156, 4);
}
.key-error-notiication p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    color: #AC1337;
}
.key-switch{
    display: flex;
    align-items: center;
}
.key-switch-inner{
    margin-left: 32px;
    margin-right: 32px;
}
.key-switch p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    color: #000000;
}
.key-body{
    width: 75%;
}
.secret-key{
    margin-top: 37px;
}
.secret-key-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    color: #000000;
}
.secret-key-form{
    margin-top:10px;
    display: flex;
    align-items: center;
}
.secret-input{
    width: 62%;
    display: flex;
    background-color: white;
    padding: 1.1875rem;
    margin-right: 23px;
}
.secret-input input{
    width: 90%;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.4);
}
.secret-input input::placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.4);
}
.secret-icon{
    width: 15%;
    display: flex;
    justify-content: space-between;
}
.secret-icon svg{
    font-size: 1rem;
    margin-left: 10px;
    color: #555555b8;
}
.secret-submit{
    width: 30%;
}
.secret-submit button, .secret-submit-2 button{
    padding: 0.5rem 1.25rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 34px;
    color: #FFFFFF;
    background: rgba(5, 139, 66, 0.72);
    border-radius: 4.6px;
    border: none;
}
.secret-submit-2 button{
    padding: 0.5rem 1.5rem;
    background-color: #AC1337;
}
@media (max-width: 1210px) {
    .secret-submit{
        width: 30%;
    }
    .secret-submit button, .secret-submit-2 button{
        font-size: 1rem;
    }
}
@media (max-width: 1000px) {
    .key-switch p{
        font-size: 0.75rem;
    }
    .key-switch-inner{
        margin-left: 8px;
        margin-right: 8px;
    }
    .key-body{
        width: 100%;
    }
    .secret-key{
        margin-top: 15px;
    }
    .secret-key-head{
        font-size: 0.75rem;
        color: #000000;
    }
    .secret-key-form{
        margin-top:8px;
    }
    .secret-input{
        padding: 0.8rem;
    }
    .secret-icon{
        width: 25%;
    }
    .secret-input input::placeholder{
        font-size: 0.625rem;
    }
    .secret-icon svg{
        font-size: 0.8rem;
        margin-left: 10px;
        color: #555555b8;
    }
    .secret-submit button, .secret-submit-2 button{
        padding: 0.5rem 1rem;
        font-size: 0.525rem;
        line-height: 13px;
    }
}