.director-modal{
    width: 100%;
}
.modal-field, .director-modal form select{
    margin-top: 30px;
    width: 100%;
    height: 60px;
    padding-left: 52px;
    background: #F6F6F6;
    border: 0.4px solid rgba(0, 0, 0, 0.8);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.8);
}
.modal-field::placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.8);
}
.modal-submit{
    margin-top: 24px;
    padding-top:1.03125rem;
    padding-bottom:1.03125rem;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.625rem;
    line-height: 39px;
    color: #FFFFFF;
    background: #AC1337;
    border-radius: 8px;
    border: none;
}
@media (max-width:1000px) {

    .modal-field, .director-modal form select{
        height: 38px;
        padding-left: 24px;
        font-size: 0.5625rem;
    }
    .modal-field::placeholder{
        font-size:0.5625rem;
    }
    .modal-submit{
        font-size: 0.75rem;
        line-height: 13px;
    }
}
