.settings{
    padding: 2rem 2.0625rem;
}
.settings-top{
    display: flex;
    border-bottom: 2px solid #D9D9D9;
}
.settings-top p{
    cursor: pointer;
    padding: 0px 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    color: #2B2B2B;
}
.settings-body{
    width: 100%;
    margin-top: 61px;
}
@media (max-width:1000px) {
    .settings{
        padding: 2rem 1rem;
    }
    .settings-top p{
        padding: 0px 20px;
        font-size: 0.65rem;
    }
}
@media (max-width:500px) {
    .settings{
        overflow-x: hidden;
    }
}