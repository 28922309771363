.notification{
    padding: 2rem 2rem;
}
.notification-outer{
    width: 100%;
}
.notification-top{
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 50px;
}
.notification-header{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    color: #022A20;
}
.do-not-disturb{
    display: flex;
    align-items: center;
}
.do-not-disturb p{
    margin-right: 5px;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    color: #022A20;
}
.notification-inner{
    display: flex;
    justify-content: center;
    width: 100%;
}
.notification-center{
    width: 90%;
}
.notification-nav{
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0px 30px;
    border-bottom: 1px solid #A5A3A3;
}
.all,.today,.clear-all{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s ease-out;
}
.all-text, .today-text, .clear-text{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #022A20;
}
.all-number, .today-number{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: black;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content : center;
    color: #FFD8EA;
}
.today-number{
    background-color: #DDDDDD;
    color: #09D1A2;
}
.today{
    border-bottom: 2px solid  #08AE87;
    transition: 0.1s ease-in;
}
.clear-all{
    color: #BA1A1A;
    font-size: 18px;
}
.clear-text{
    color: #BA1A1A;
}
.all:hover{
    border-bottom: 2px solid  #000000;
    transition: 0.1s ease-in;
}
.clear-all:hover{
    border-bottom: 2px solid #BA1A1A;
    transition: 0.1s ease-in;
}
.notification-messages{
    background-color: #ffffff;
    border-radius: 5px;
    margin-top: 30px;
}
.messages{
    padding: 20px;
}
.messages-inner{
    margin-top: 15px;
    display: flex;
    align-items: center;
    padding: 20px;
}
.messages-inner:hover{
    background-color: #F2F2F2;
    transition: all 0.1s;
}
.message-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 13px;
}
.message-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.message-text{
    width: 60%;
}
.message-title{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: #2B2B2B;
}
.message-content{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #068B6C;
}
.date-time{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 15.3451px;
    color: #2B2B2B;
}
.date-time span{
    font-weight: 700;
}
.delete{
    margin-top: 9px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.delete-buttons{
    margin-right: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
}
.yes{
    width: 36px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #056951;
    border: none;
    border-radius: 3px;
    text-align: center;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    color: #FFFFFF;
}
.no{
    width: 36px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
    border: 1px solid #BA1A1A;
    color: #BA1A1A;
    border-radius: 3px;
}
.delete span{
    font-size: 22px;
}
.message-tick{
    font-size: 0.2rem;
    margin-right: 10px;
}
.notification-search{
    display: none;
}
.notification-back{
    display: none;
}
@media (max-width: 1000px) {
    .notification{
        padding: 2rem 1rem;
    }
    .notification-top{
        padding: 15px;
    }
    .notification-header{
        font-size: 1rem;
    }
    .do-not-disturb p{
        font-size: 0.75rem;
    }
    .notification-nav{
        padding: 10px 10px 0px 10px;
    }
    .all,.today,.clear-all{
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.1s ease-out;
    }
    .all-text, .today-text, .clear-text{
        font-size: 0.875rem;
    }
    .all-number, .today-number{
        width: 14px;
        height: 14px;
        font-size: 0.5625rem;
    }
    .today-number{
        width: 14px;
        height: 14px;
        font-size: 0.5625rem;
    }
    .today{
        border-bottom: 2px solid  #08AE87;
        transition: 0.1s ease-in;
    }
    .clear-all{
        color: #BA1A1A;
        font-size: 18px;
    }
    .clear-text{
        color: #BA1A1A;
    }
    .all:hover{
        border-bottom: 2px solid  #000000;
        transition: 0.1s ease-in;
    }
    .clear-all:hover{
        border-bottom: 2px solid #BA1A1A;
        transition: 0.1s ease-in;
    }
    .notification-messages{
        background-color: transparent;
    }
    .messages{
        padding: 0px;
    }
    .message-date{
        display: none;
    }
    .messages-inner{
        padding: 10px;
        margin-top: 2px;
        overflow-x: hidden;
    }
    .message-content{
        font-size: 12px;
        width: 100%;
        overflow: hidden;
    }
    .message-date-delete{
       margin-left: 10px;
    }
    .date-time{
        font-size: 10px;
    }
    /* .delete span{
        display: none;
    } */
    .message-text{
        width: 100%;
    }
}