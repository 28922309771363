.dashboard{
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
}
.dashboardLeft{
    padding:0rem 1.7rem;
    width: 75%;
    height: 88vh;
    overflow-y: scroll;
}
.dashboardLeft::-webkit-scrollbar {
    width: 8px;
}
.dashboardLeft::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #D9D9D9;
    border-radius: 10px;
}
.dashboardLeft::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 15px;
}  
.dashboardRight{
    width: 25%;
}
.dashboardCategory{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.categoryLeftMobile{
    display: none;
}
.categoryLeftDesktop{
    display: block;
}
.categoryLeft{
    width: 65%;
    display: flex;
    align-items: center;
    gap: 12px;
}
.dropdownButton{
    display: none;
}
.status{
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: 0.5px solid rgba(43, 43, 43, 0.4);
    border-radius: 8px;
}
.completeProfile{
    width: 100%;
    margin-top: 56px;
    padding: 0.5rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    background-color: #FAF1F3;
}
.completeProfile p{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #B11226;
}
.completeProfile p button{
    margin-left: 8px;
    padding: 0.4rem;
    background: #F2F2F2;
    border: none;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 30px;
    color: #555555;
}
.status p{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #2B2B2B;
}
.active{
    padding: 0.5rem 1rem;
    background-color:  #B11226;
    border-radius: 8px;
    border: none;
}
.active p{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
}
.categoryRight{
    width: 35%;
    display: flex;
    align-items: center;
    gap: 12px;
}
.categoryRight select, .categoryRight input{
    width: 100%;
    padding: 0.5rem 1rem;
    background: #FFFFFF;
    border: none;
    border-radius: 8px;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #2b2b2baf;
}
.categoryRight select optgroup{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #2B2B2B;
    opacity: 0.3;
}
.money{
    width: 15%;
    display: flex;
    align-items: center;
    gap: 12px;
}
.money select{
    width: 100%;
    padding: 0.5rem 1rem;
    background: #FFFFFF;
    border: none;
    border-radius: 8px;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #2b2b2baf;
}
.money select optgroup{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #2B2B2B;
    opacity: 0.3;
}

.categorySearch{
    display: flex;
    align-items: center;
    padding: 0.8125rem;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 8px;
}
.categorySearch input{
    padding-left: 10px;
    background-color: transparent;
    border: none;
}
.categorySearch input::placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
}
.dashboardBalance{
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.balance{
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    color: rgba(43, 43, 43, 0.8);
}
.balanceContainer{
    display: flex;
}
.balanceContainer p{
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: rgba(43, 43, 43, 0.8);
}
.balanceIcon{
    display: flex;
    margin-left: 54px;
}
.balanceIcon svg{
    margin-right: 8px;
    font-size: 1.2rem;
    color: #555555;
}
.graphContainer{
    margin-top: 45px;
    width: 100%;
}
.businessAnalytics{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #2B2B2B;
}

.graphInner{
    margin-top: 16px;
    width: 100%;
    height: 400px;
    background: #FFFFFF;
    padding: 1.7375rem;
    border-radius: 6.5198px;
}
.tableContainer{
    margin-top: 17px;
    width: 100%;
}
@media (max-width: 1280px) {
    .dashboard{
        padding: 2rem 1rem;
    }
    .dashboardLeft{
        padding:0rem 1rem;
        width: 70%;
    }
    .dashboardRight{
        width: 30%;
    }
    .balance{
        font-size: 1.15rem;
    }
    .balanceContainer{
        display: flex;
    }
    .balanceContainer p{
        font-size: 1.8rem;
    }
    .balanceIcon svg{
        font-size: 1rem;
    }
}
@media (max-width: 1100px) {
    .categoryLeft{
        width: 60%;
        gap: 5px;
    }
    .status{
        padding: 0.5rem 0.8rem;
    }
    .status p{
        font-size: 0.9375rem;
    }
    .active{
        padding: 0.5rem 0.8rem;
    }
    .graphInner{
        height: 250px;
    }
    .active p{
        font-size: 0.9375rem;
    }
    .categoryRight{
        width: 40%;
        gap: 5px;
    }
}
@media (max-width: 1000px) {
    .dashboard{
        padding: 2rem 1rem;
        display: block;
        overflow-x: hidden;
    }
    .dashboardCategory{
        display: flex;
        justify-content: space-between;
        align-items: first baseline;
    }
    .categoryLeftMobile{
        display: block;
        width: 30%;
    }
    .categoryLeftDesktop{
        display: none;
    }
    .categoryLeft{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: left;
        gap: 0px;
    }
    .categoryLeftInner{
        padding: 0.5rem;
        width: 100%;
        background-color: white;
    }
    .categoryRight{
        width: 60%;
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .dropdownButton{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        background-color: #B11226;
        border-radius: 4px;
        padding: 0.5rem;
    }
    .dashboardLeft{
        padding:0rem 0rem;
        width: 100%;
        height: 100%;
        overflow-y: visible;
    }
    .balance{
        font-size: 0.75rem;
    }
    .balanceContainer p{
        font-size: 1.5rem;
    }
    .balanceIcon{
        margin-left: 30px;
    }
    
    .graphInner{
        margin-top: 16px;
        background: #FFFFFF;
        padding: 0.4rem;
        border-radius: 6.5198px;
    }
    .dashboardRight{
        margin-top: 20px;
        width: 100%;
    }
}
@media (max-width: 500px) {
    .graphInner{
        width: 120%;
        overflow-x: hidden;
        margin-top: 16px;
        background: #FFFFFF;
        padding: 0.2rem;
        border-radius: 6.5198px;
    }
}
