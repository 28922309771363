.main-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-top p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    color: rgba(43, 43, 43, 0.7);
}
.main-top button{
    padding: 1.15625rem 2.99375rem;
    background: #AC1337;
    border: none;
    border-radius: 4.6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4375rem;
    line-height: 34px;
    color: #FFFFFF;
}
.main-body-inner{
    margin-top: 60px;
    width: 30%;
    padding: 1.5rem;
    background: #FFFFFF;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}
.main-account-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.9);
}
.main-account-phone{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.4);
}
.main-balance-2{
    margin-top: 71px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-ava-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: rgba(43, 43, 43, 0.4);
}
.main-amount{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.9);
}
.main-current{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 29.54px;
    background: rgba(179, 28, 47, 0.15);
    border-radius: 4px;
}
.main-current p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8125rem;
    color: rgba(43, 43, 43, 0.9);
}
.main-footer{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-footer-left{
    display: flex;
    align-items: center;
}
.main-select{
    margin-left: 20px;
}
.main-select select{
    width: 75%;
    padding: 0.4rem;
    background-color: #B11226;
    border-radius: 16px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
}
.main-footer-left p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 120%;
    color: rgba(43, 43, 43, 0.4);
}
.css-19micn4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: #B11226;
}
@media (max-width: 1210px) {
    .main-body-inner{
        width: 65%;
    }
    .main-top p{
        font-size: 1.3rem;
        color: rgba(43, 43, 43, 0.7);
    }
    .main-top button{
        padding: 1.15625rem 2.99375rem;
        font-size: 1.375rem;
    }
    .main-account-name{
        font-size: 1.8rem;
        color: rgba(0, 0, 0, 0.9);
    }
    .main-amount{
        font-size: 2rem;
        color: rgba(0, 0, 0, 0.9);
    }
}
@media (max-width:1000px) {
    .main-top p{
        font-size: 1rem;;
    }
    .main-top button{
        position: absolute;
        top: 10px;
        right: 16px;
        padding: 0.575rem 1.4375rem;
        font-size: 0.6875rem;
        line-height: 13px;
    }
    .main-body-inner{
        width: 100%;
        padding: 1.5rem;
    }
    .main-account-name{
        font-size: 1.5rem;
    }
    .main-account-phone{
        font-size: 1.125rem;
    }
    .main-ava-text{
        font-size: 0.9375rem;
    }
    .main-amount{
        font-size: 1.875rem;
    }
    .main-current p{
        font-size: 0.625rem;
    }
    .main-select select{
        font-size: 1rem;
    }
    .main-footer-left p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 120%;
        color: rgba(43, 43, 43, 0.4);
    }
}