.navbar{
    position: fixed;
    height: 56px;
    width: calc(100% - 250px);
    padding: 1rem;
    background-color: #ffffff;
    z-index: 1;
}
.navbarBar{
    display: none;
}
.navbarinner{
    display: flex;
    align-items: center;
    justify-content: right;
}
.profileicon{
    display: flex;
    align-items: center;
}
.personicon{
    margin-left: 8px;
    font-size: 1.8rem;
    color: #0000004D;
}
.chevron{
    margin-left: 8px;
    font-size: 1.25rem;
    color: #00000099;
}
@media (max-width: 1000px){
    .navbar{
        background-color: #F5F5F5;
        width: 100%;
    }
    .navbarBar{
        display: block;
    }
    .navbarinner{
        justify-content: space-between;
    }
}
