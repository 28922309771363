.onetime{
    padding: 2rem 7rem;
}
.onetime-head{
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.25rem;
    color: #2B2B2B;
}
.onetime-body{
    margin-top: 20px;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    color: #000000;
}
.onetime-button{
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: right;
}
.onetime-button button{
    padding:1.125rem 1.1875rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4375rem;
    color: #FFFFFF;
    background: #AC1337;
    border-radius: 4.6px;
    border: none;
}
@media (max-width: 1000px) {
    .onetime{
        padding: 2rem 2rem;
    }
    .onetime-head{
        font-size: 1.5rem;
    }
    .onetime-body{
        text-align: center;
        font-size: 1rem;
    }
    .onetime button{
        padding:1.125rem 1.1875rem;
        font-size: 0.6875rem;
    }
}