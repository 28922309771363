.modal-background{
    z-index: 2;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}
.modalClose{
    position: absolute;
    right: 0;
    top: -40px;
    background-color: white;
    height: 25px;
    width: 25px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.459);
    border-radius: 50%;
}
.modal{
    position: relative;
    padding: 1.5rem;
    width: 30%;
    background-color: white;
    border-radius: 8px;
}
.modals{
    padding: 1.4rem;
    width: 40%;
}
.create-payment{
    width: 100%;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: #2B2B2B;
}
.modal-inner{
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
}
.modal-left, .modal-right{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.modal-left svg, .modal-right svg{
    font-size: 3rem;
    color: #B11226B2;
}
.modal-left-icon, .modal-left a, .modal-right a{
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
}
.payment-method{
    margin-top: 8px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    color: #2B2B2B;
}
.payment-details{
    margin-top: 8px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #2B2B2B;
}

.payment-choose{
    margin-top: 16px;
    padding: 0.4375rem 1.9375rem;
    background: #F2F2F2;
    border: none;
    border-radius: 2.31351px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6875rem;
    line-height: 17px;
    color: #555555;
}
@media (max-width: 1356px){
    .modal-left, .modal-right{
        width: 43%;
    }
}
@media (max-width: 1280px){
    .modal{
        width: 55%;
    }
    .modal-left, .modal-right{
        width: 43%;
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 1024px){
    .modal{
        width: 58%;
    }
    .modal-left, .modal-right{
        width: 43%;
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 1000px){
    .modal{
        width: 80%;
        padding: 35px;
        padding-bottom: 35px;
    }
    .modal-inner{
        margin-top: 48px;
        display: flex;
        justify-content: space-between;
    }
    .create-payment{
        font-size: 0.75rem;
    }
    .modal-left svg, .modal-right svg{
        font-size: 2rem;
    }
    .payment-method{
        font-size: 0.635625rem;
        color: #2B2B2B;
    }
    .payment-details{
        font-size: 0.4375rem;
    }
    .payment-choose{
        font-size: 0.5875rem;

    }
}