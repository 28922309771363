.account-page{
    padding: 1.8rem 2rem;
    position: relative;
}
.accounts-body{
    margin-top: 31px;
}
@media (max-width:1000px) {
    .account-page{
        padding: 1.8rem 1rem;
    }
    .accounts-top{
        margin-top: 40px;
    }
}