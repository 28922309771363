.money-in-out{
    background-color: #FFFFFF;
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
}
.money-in-text, .money-out-text{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 0.6625rem;
    color: #555555;
    display: flex;
    align-items: center;
}
.money-in-text span{
    color: #068B6C;
}
.money-in-amount, .money-out-amount{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 120%;
    color: #068B6C;
}
.money-out-text span, .money-out-amount{
    color: #D50000;
}
@media (max-width: 1210px) {
    .money-in-out{
        padding: 0.8rem;
    }
    .money-in-amount, .money-out-amount{
        font-size: 1rem;
    }
}