.vas-outer{
    padding: 2rem 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vas{
    width: 40%;
    padding: 3rem 1.96rem;
    background-color: white;
}
/* .vas-content{
    width: 45%;
} */
.vas-services{
    margin-top: 20px;
    color: #1B232D;
    font-family: "Inter";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.services{
    margin-top: 42px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 13.7px;
}
.service{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 138.515px; */
    height: 133.524px;
    padding: 0.78rem 0.78rem;
    border-radius: 0.62394rem;
    background: #FFF;
    box-shadow: 0px 4.991549015045166px 16.98374366760254px 0px rgba(0, 0, 0, 0.09);
}
.service-icon svg{
    font-size: 1.2rem;
}
.phone-icon svg{
    color: #99081A;
}
.data-icon svg{
    color: #39A430;
}
.service p{
    margin-top: 9.8px;
    color: #1B232D;
    font-family: "Poppins";
    font-size: 0.93594rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.vas-button{
    margin-top: 0px;
}
.vas-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgb(96, 96, 96);
    text-transform: uppercase;
}
.vas-body-2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
}
@media (max-width: 1100px) {
    .vas-outer{
        padding: 2rem 0.5rem;
    }
    .vas{
        width: 45%;
        padding: 3rem 1rem;
    }
}
@media (max-width: 1000px) {
    .vas-outer{
        padding: 2rem 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .vas{
        width: 100%;
        padding: 3rem 1.96rem;
        background-color: transparent;
    }
    .service{
        height: 150.524px;
    }
}