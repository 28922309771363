.input label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.8);
}
.input input{
    margin-top: 16px;
    background: #FFFF;
    border-radius: 4px;
    border: none;
    width: 100%;
    padding: 1.0625rem 1.875rem;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    color: #2B2B2B;
}
.input select{
    margin-top: 16px;
    background: #FFFF;
    border-radius: 4px;
    border: none;
    width: 100%;
    padding: 1.0625rem 1.875rem;
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    color: #2B2B2B;
}
.input input::placeholder{
    font-family: 'Darker Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    color: #2b2b2b85;
}