.payment{
    padding: 2rem 2.0625rem;
}
.payment-top{
    width: 100%;
    display: flex;
    justify-content: right;
}
.new-payment{
    padding: 0.6875rem 2.9375rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 30px;
    color: #FFFFFF;
    background: #AC1337;
    border: none;
    border-radius: 4px;
}
.payment-body{
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.payment-body svg{
    font-size: 4rem;
    color: #D11C1C;
}
.no-payment-head{
    width: 800px;
    margin-top: 21px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: #2B2B2B;
}
.no-payment-body{
    width: 600px;
    margin-top: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center;
    color: rgba(43, 43, 43, 0.8);
}
@media (max-width: 1024px){
    .no-payment-body{
        font-size: 1rem;
    }
}
@media (max-width: 1000px){
    .payment{
        padding: 2rem 1.0625rem;
    }
    .new-payment{
        padding: 0.4875rem 1.9375rem;
        font-size: 0.70625rem;
    }
    .no-payment-head{
        font-size: 1rem;
    }
    .no-payment-body{
        width: 100%;
        margin-left: 70px;
        margin-right: 70px;
        font-size: 0.875rem;
    }
}