.fieldlabel{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.1px;
    color: #202020;
}

.fieldinput{
    margin-top: 8px;
    width: 100%;
    height: 48px;
    padding-left: 1rem;
    background: #F9FAFB;
    border: 1px solid #EEF6FF;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: rgba(32, 32, 32, 0.8);
}
.fieldinput optgroup, .fieldinput optgroup option{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: rgba(32, 32, 32, 0.8);
}
.fieldtext{
    margin-top: 8px;
    width: 100%;
    height: 99px;
    padding: 1rem;
    background: #F9FAFB;
    border: 1px solid #EEF6FF;
    border-radius: 4px;
}
@media (max-width: 1000px) {
    .fieldlabel{
        font-size: 0.625rem;
    }
    .fieldinput{
        font-size: 0.6875rem;
    }
}