.document-head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.9);
}
.document-body{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
}
@media (max-width: 1000px) {
    .document img{
        width: 70%;
    }
}