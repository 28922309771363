.login{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-color: rgb(249, 249, 249);
}
.login-logo p{
    position: absolute;
    padding-top: 10px;
    padding-left: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size:35px;
    color: #B11226;
}
.login-right{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-form-section-inner{
    width: 30%;
    background-color: white;
    padding: 40px;
    z-index: 2;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 10px -3px rgba(102, 102, 102, 0.473);
    -moz-box-shadow: 0px 0px 10px -3px rgba(102,102,102,0.473);
    box-shadow: 0px 0px 10px -3px rgba(102,102,102,0.473);
}
.login-header{
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 60px;
    background: linear-gradient(180deg, #000000 20.05%, rgba(198, 9, 9, 0.83) 128.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.login-error{
    margin-top: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    color: #B11226;
}
.login-text{
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    background: linear-gradient(180deg, #000000 20.05%, rgba(198, 9, 9, 0.83) 128.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; 
}
.css-1xc3v61-indicatorContainer svg{
    display: none;
}
.css-13cymwt-control{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
    padding: 13px;
    margin-top: 10px;
    width: 100%;

    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}
.password-icon{
    width: 40px;
    z-index: 6;
}
.forget-password a{
    text-decoration: none;
}
.forget-password p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: rgba(177, 18, 38, 0.7);
}
.submit{
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 20px;
    height: 72px;
    background: #AC1337;
    border-radius: 10px;
}
.submit-login{
    height: 49px;
}
.submit input{
    background-color: transparent;
    border: none;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
}
.submit button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
}
.inputfield-4{
    margin-top: 20px;
}
.inputfield-4 label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
}
.loginfield label{
    font-size: 16px;
}
.inputbox2{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}
.inputbox2 input{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}
.inputbox-login input{
    height: 54px;
}
.inputbox2 input::placeholder{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
}
.inputbox-login input::placeholder{
    font-size: 13px;
}
.place-mobile{
    display: none;
}
.psw-visible{
    margin-left: -50px;
    margin-top: 10px;
}
.forget-password{
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: rgba(177, 18, 38, 0.7);
}
.account{
    margin-top: 17px;
}
.account p{
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.9);
}
.account p span{
    color: rgba(177, 18, 38, 0.7);
}
.circle-background{
    position: absolute;
    top: 30%;
    left: 50%;
    width: 500px;
    height: 500px;
    z-index: 1;
    border-radius: 50%;
    background-color: #ac13370a;
}
@media (max-width: 1200px) {
    .login-form-section-inner{
        width: 50%;
    }
}
@media (max-width: 1000px) {
    .login-logo p{
        font-size:30px;
        padding-left: 20px;
        padding-top: 20px;
    }
    .login-left{
        display: none;
    }
    .login-header{
        font-size: 30px;
    }
    .login-text{
        text-align: left;
        font-size: 11px;
    }
    .login-right{
        padding: 0px;
    }
    .login-form-section-inner{
        width: 70%;
    }
    .circle-background{
        position: absolute;
        top: 10%;
        left: 50%;
        width: 400px;
        height: 400px;
        z-index: 1;
        border-radius: 50%;
        background-color: #f1f1f1;
    }
}
@media (max-width: 850px) {
    .circle-background{
        position: absolute;
        top: 10%;
        left: 60%;
        width: 250px;
        height: 250px;
    }
    .inputfield-4 label{
        display: none;
    }
    .inputbox2 input{
        border: 1px solid #982C31;
    }
    .inputbox2 input::placeholder{
        visibility: hidden;
    }
    
    .place-mobile{
        display: block;
        position:absolute;
        top: 0;
        left: 0;
        padding: 20px;
        pointer-events: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.5);
        transition: 0.5s;
    }
    .inputbox2 input:focus ~ .place-mobile,
    .inputbox2 input:valid ~ .place-mobile{
        transform: translateX(35px) translateY(-5px);
        font-size: 18px;
        padding: 0 15px; 
        background-color:  white;   
    }
}
@media (max-width: 650px) {
    .circle-background{
        display: none;
    }
    .login-logo p{
        font-size:25px;
        padding-left: 20px;
        padding-top: 20px;
    }
    .login-form-section-inner{
        width: 90%;
    }
}