
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700;800&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input:focus{
  outline: #dcdcdc;
}
.test1{
  overflow-y: hidden;
}
.test{
  width: 100%;
  display: flex;
}
/* iframe
{
    display: none;
} */
a{
  text-decoration: none;
}
.right{
  margin-left: 250px;
  width: calc(100% - 250px);
}
.content{
  min-height: calc(100vh - 56px);
  max-height: 100%;
  margin-top: 56px;
  background-color: #F5F5F5 ;
}
.spinner{
  width: 20%;
}
@media (max-width: 1100px){
  .content{
    min-height: 100vh ;
    max-height: 100%;
    margin-top: 56px;
    background-color: #F5F5F5 ;
  }
}
@media (max-width: 1000px){
  .right{
    margin-left: 0px;
    width: 100%;
  }
}