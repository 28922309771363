.rightbar{
    background-color: #F9FAFB;
    width: 100%;
    height: 88vh;
}

.paymentContainer{
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 1rem 1.7rem;
}
.completeProfile{
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 0.8125rem 2rem;
}
.completeProfile p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #555555;
}
.completeProfile button{
    margin-top: 8px;
    width: 100%;
    background: #F2F2F2;
    border-radius: 8px;
    border: none;
    padding: 0.3125rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 30px;
    color: #555555;
}